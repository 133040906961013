import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

// Utils
import { DomainRouting } from 'src/app/core/utils';

// Services
import { UsersService } from 'src/app/core/services/users';
import { LoginService } from 'src/app/core/services/login';
import { environment } from 'src/environments/environment';
import { DomainTypes } from '../models/masters/masters.enum';


@Injectable()
export class DemoValidationGuard  {
    public constructor(private router: Router,
        private domainRoutingService: DomainRouting,
        private usersService: UsersService,
        private loginService: LoginService) { }

    public canActivate() {
        let isDemo: boolean = environment.domain === DomainTypes.DEMO ? true : false

        if(isDemo)
            this.router.navigate([`/login`]);
        return true

    }
}
